import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  getDocs,
  where,
} from "firebase/firestore";
import React from "react";
import { CSVLink } from "react-csv";
import "./ComplianceReport.css";

const ComplianceReport: React.FC = () => {

  const [csvData, setCsvData] = React.useState([]);

  
  const resetReport = () => {
    setCsvData([]);
  };

  const getMembers = async () => {
    var members = [];

    // get all docs from users
    const q = query(collection(getFirestore(), "users"), where("role", "==", "member"));

    members = [];

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const row = {
        external_id: doc.data().externalID,
        email: doc.data().email,
        employer_name: doc.data().groupID,
        active: doc.data().active ? doc.data().hasQuestis ? "A" : "D" : "D",
        first: doc.data().firstName,
        last: doc.data().lastName,
        middle: "",
        nickname: "",
        annual_income: "",
        dob: doc.data().dob,
        mobile_phone: doc.data().phone ? doc.data().phone.replace(/-/g, "") : "",
        address1: doc.data().addr1,
        address2: doc.data().addr2,
        city: doc.data().city,
        state: doc.data().state,
        zipcode: doc.data().zip,
        country: "",
        gender: doc.data().gender === "male" ? "M" : "F",
        marital_status: "",
        work_phone: "",
        work_phone_ext: "",
        home_phone: "",
        hire_date: "",
        employment_status: "",
        job_title: "",
      };

      members.push(row);
    });

    setCsvData(members);
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Questis Report</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {csvData.length === 0 ? (
          <IonButton onClick={getMembers}>Get Report</IonButton>
        ) : (
          <>
            <CSVLink
              data={csvData}
              className="downloadButton"
              filename={"QuestisReport.csv"}
            >
              Download Report Here
            </CSVLink>{" "}
            <br></br>
            <IonButton onClick={resetReport}>New Report</IonButton>
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default ComplianceReport;
