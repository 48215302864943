import { addDoc, collection, getFirestore, query, where, orderBy, getDocs } from "@firebase/firestore";
import { useIonAlert, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea, IonButton } from "@ionic/react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import React, { useContext } from "react";
import { UserContext } from "../../../lib/context";
import "./style.css";

const MassSMSCard: React.FC = () => {

    const [groups, setGroups] = React.useState([]);
    const [selectedGroup, setSelectedGroup] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [attachment, setAttachment] = React.useState();
    const [presentAlert] = useIonAlert();
  
    const { user, profile } = useContext(UserContext);
  
    const sendMessage = async () => {
      if (!selectedGroup) {
        presentAlert({
          message: "Please select a group",
          buttons: ["OK"],
        });
        return;
      }
  
      if (!message) {
        presentAlert({
          message: "Please enter a message",
          buttons: ["OK"],
        });
        return;
      }
  
      console.log("Sending message");
      var data = {
        group: selectedGroup,
        message: message,
        attachment: null,
        date: new Date(),
        creator: user.uid,
        creatorName: profile.firstName + " " + profile.lastName,
        from: process.env.REACT_APP_SMS_FROM_NUMBER,
      };
  
      console.log(data);
  
      if (attachment) {
        // upload attachment to firebase storage
  
        const storage = getStorage();
        const storageRef = ref(storage, "messages/" + attachment["name"]);
        const snapshot = await uploadBytes(storageRef, attachment);
  
        // get download url
        const downloadURL = await getDownloadURL(snapshot.ref);
  
        data.attachment = downloadURL;
  
        // console.log("File available at", downloadURL);
        addDoc(collection(getFirestore(), "massSmsMessage"), data)
          .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
            setAttachment(null);
            setMessage("");
            setSelectedGroup("");
            presentAlert({
              message: "Message sent successfully",
              buttons: ["OK"],
            });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      } else {
        console.log("No attachment");
  
        addDoc(collection(getFirestore(), "massSmsMessage"), data)
          .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
            setAttachment(null);
            setMessage("");
            setSelectedGroup("");
            presentAlert({
              message: "Message sent successfully",
              buttons: ["OK"],
            });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      }
    };
  
    const getGroups = async () => {
      const q = query(
        collection(getFirestore(), "groups"),
        where("active", "==", true),
        orderBy("group", "asc")
      );
  
      var groupsData = [];
  
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        groupsData.push(doc.data());
      });
  
      // sort groups by group name alphabetically
      groupsData.sort((a, b) => {
        if (a.group < b.group) {
          return -1;
        }
        if (a.group > b.group) {
          return 1;
        }
        return 0;
      });
  
      setGroups(groupsData);
    };
  
    // React.useEffect(() => {
    //   getGroups();
    // }, []);
  
    const addFile = async (e: any) => {
      document.getElementById("fileInput").click();
    };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Mass SMS</IonCardTitle>
        <IonCardSubtitle>Send a mass SMS to all members</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItem>
          <IonLabel position="floating">Group</IonLabel>
          <IonSelect
            onFocus={(e) => getGroups()}
            cancelText="Cancel"
            value={selectedGroup}
            okText="Okay"
            onIonChange={(e) => {
              setSelectedGroup(e.detail.value);
            }}
          >
            <IonSelectOption value="all">All</IonSelectOption>
            {groups.map((group) => (
              <IonSelectOption value={group.groupID}>
                {group.group}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Message</IonLabel>
          <IonTextarea
            autoGrow={true}
            value={message}
            placeholder=""
            onIonInput={(e) => setMessage(e.target.value)}
          ></IonTextarea>
        </IonItem>
        <p>
          <input
            type="file"
            id="fileInput"
            className="attachFileLinkButton"
            onChange={(e: any) => setAttachment(e.target.files[0])}
          ></input>
        </p>

        {!attachment ? (
          <p className="attachFileLink" onClick={addFile}>
            Attach File
          </p>
        ) : (
          <p className="attachFileLink" onClick={addFile}>
            {attachment["name"]}{" "}
          </p>
        )}

        <IonButton
          className="ion-margin-top"
          disabled={!selectedGroup || !message}
          onClick={() =>
            presentAlert({
              message: "Are you sure you want to send this?",
              buttons: [
                {
                  text: "Cancel",
                  role: "cancel",
                  handler: () => {},
                },
                {
                  text: "OK",
                  role: "confirm",
                  handler: () => {
                    sendMessage();
                  },
                },
              ],
            })
          }
        >
          Send
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default MassSMSCard;
