import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonHeader,
  IonCardTitle,
  IonTitle,
  IonToolbar,
  IonCardContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonButton,
} from "@ionic/react";
import "./Add.css";
import "./Home.css";
import React, { useRef } from "react";
import UpcomingSessions from "../../components/Appointment/UpcomingSessions";
import Notifications from "../../components/Notifications/Notifications";
import ThisWeekSessions from "../../components/Appointment/ThisWeekSessions";
import {
  query,
  collection,
  where,
  getFirestore,
  getDocs,
  updateDoc,
  Timestamp,
  setDoc,
  doc,
} from "@firebase/firestore";
import { get } from "http";

import { InterestList } from "../../Interfaces/Interest";

import { ClinicalConcernsList } from "../../Interfaces/ClinicalConcerns";

import { GoalsList } from "../../Interfaces/Goals";

const Home: React.FC = () => {
  const [newAvatarUrl, setNewAvatarUrl] = React.useState<string | null>(null);




  return (
    <IonPage>
      <IonContent>
        
        <IonRow>
          <IonCol size="sm">
            <IonCard className="grouplist">
              <IonCardTitle>
                <IonToolbar color="secondary">NOTIFICATIONS</IonToolbar>
              </IonCardTitle>
              <IonList>
                <Notifications />
              </IonList>
            </IonCard>
          </IonCol>
          <IonCol>
            <IonCard className="grouplist">
              <IonCardTitle>
                <IonToolbar color="secondary">SCHEDULE</IonToolbar>
              </IonCardTitle>
              <IonList>
                <ThisWeekSessions />
              </IonList>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Home;
