import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

import "./Communication.css";
import MassSMSCard from "../../components/Communication/MassSMSCard";

const Communication: React.FC = () => {
 

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Communication</IonTitle>
          </IonToolbar>
        </IonHeader>
        <MassSMSCard />
        
      </IonContent>
    </IonPage>
  );
};

export default Communication;
