//tempalte for component in react
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { doc, getFirestore, writeBatch } from "firebase/firestore";
import React from "react";

export default function AddGroup() {
  const [group, setGroup] = React.useState<string>();
  const [groupID, setGroupID] = React.useState<string>();

  // this is what handles when the from is submited
  const addGroup = async (e: { preventDefault: () => void; target }) => {
    e.preventDefault();

    const userDoc = doc(getFirestore(), "groups", groupID);

    // Commit both docs together as a batch write.
    const batch = writeBatch(getFirestore());

    const groupObj = {
      group: group,
      groupID: groupID,
      active: true,
    };

    batch.set(userDoc, groupObj);

    batch.commit();

    e.target.reset();
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Add Group</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <form onSubmit={addGroup}>
          <IonItem>
            <IonLabel position="stacked">Group Name</IonLabel>
            <IonInput
              required
              placeholder=""
              onIonChange={(e) => setGroup(e.detail.value)}
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Group ID</IonLabel>
            <IonInput
              required
              placeholder=""
              onIonChange={(e) => setGroupID(e.detail.value)}
            ></IonInput>
          </IonItem>

          <div id="add-group-help"></div>

          <IonButton type="submit">Add Group</IonButton>
        </form>
      </IonCardContent>
    </IonCard>
  );
}
