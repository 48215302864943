import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { checkbox, squareOutline } from "ionicons/icons";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/context";

import "./Session.css";

const Session: React.FC = () => {
  const [appointment, setAppointment] = useState({});
  const [documents, setDocuments] = useState([]);
  const [cancelled, setCancelled] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);

  const { user } = useContext(UserContext);

  const fetchSessions = async () => {
    //get sessiosn from firebase

    //get Session id from url
    const id = window.location.pathname.split("/")[2];

    const docRef = doc(getFirestore(), "sessions", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      console.log("Document id:", docSnap.id);
      setAppointment({ id: docSnap.id, ...docSnap.data() });
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const fetchDocuments = async () => {
    //get sessiosn from firebase
    const id = window.location.pathname.split("/")[2];
    const q = query(
      collection(getFirestore(), "session_documents"),
      where("fromSession", "==", id)
    );

    var documentsList = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      documentsList.push({ id: doc.id, ...doc.data() });
    });

    setDocuments(documentsList);
  };

  const RenderDocuments = () => {
    let documentList = [];
    documents.forEach((document) => {
      documentList.push(
        <>
          <p>
            <a href={document.url}>{document.title}</a>
          </p>
        </>
      );
    });

    if (documentList.length == 0) {
      return <div>No Documents</div>;
    }

    return <>{documentList}</>;
  };

  const AddDocument = () => {
    const [documentFile, setDocumentFile] = useState<File>();

    const addFile = () => {
      const id = window.location.pathname.split("/")[2];

      if (documentFile) {
        const storage = getStorage();
        const fileRef = ref(storage, "session_documents/" + documentFile.name);
        uploadBytes(fileRef, documentFile).then(() => {
          getDownloadURL(fileRef).then(async (url) => {
            const post = {
              fromSession: id,
              title: documentFile.name,
              url: url,
              posterID: user.uid,
            };

            await addDoc(collection(getFirestore(), "session_documents"), post);
            fetchDocuments();
          });
        });
      } else {
        console.log("No file selected");
      }
    };

    return (
      <>
        <IonItem>
          <input
            type="file"
            onChange={(e) => setDocumentFile(e.target.files[0])}
          ></input>
        </IonItem>
        <IonButton expand="block" onClick={addFile}>
          Add Document
        </IonButton>
      </>
    );
  };

  const updateCoachNotes = async () => {
    const id = window.location.pathname.split("/")[2];

    const ref = doc(getFirestore(), "sessions", id);
    await updateDoc(ref, {
      coachNotes: appointment["coachNotes"],
    });
  };

  const updateCoachOnlyNotes = async () => {
    const id = window.location.pathname.split("/")[2];

    const ref = doc(getFirestore(), "sessions", id);
    await updateDoc(ref, {
      coachOnlyNotes: appointment["coachOnlyNotes"],
    });
  };

  useEffect(() => {
    fetchSessions();
    fetchDocuments();
  }, []);

  const RenderMemberInfo = ({ memberUid }) => {
    const [memberData, setMemberData] = useState({});
    const coachRef = doc(getFirestore(), "users", memberUid);
    getDoc(coachRef).then((doc) => {
      if (doc.exists()) {
        setMemberData(doc.data());
      } else {
        // doc.data() will be undefined in this case
      }
    });

    return (
      <>
        {" "}
        <IonCardHeader className="ion-text-center">
          {appointment["status"] == "cancelled" || cancelled ? (
            <b>CANCELLED</b>
          ) : (
            ""
          )}
          <p>{appointment["eventTypeName"]}</p>
          <div className="wrapper-my-coach-session">
            <img src={memberData["photoURL"]} alt="" />
          </div>
        </IonCardHeader>
        <IonCardContent>
          <h1> {memberData["firstName"] + " " + memberData["lastName"]} </h1>
          <IonButton href={"member-dash/" + appointment["memberUid"]}>Dashboard</IonButton>
          <br></br>
          {appointment["memberEmail"]}
          <p className="session-time-pretty">
            {appointment["startTimePretty"]}
          </p>
        </IonCardContent>
      </>
    );
  };

  const RenderJoinCallButton = ({ cometChatUid, startTime, location }) => {
    const startTimeArray = startTime.split("-");
    console.log(startTimeArray);
    const year = startTimeArray[0];
    const month = startTimeArray[1];
    const day = startTimeArray[2].split("T")[0];

    const isToday =
      new Date().getFullYear() == year &&
      new Date().getMonth() + 1 == month &&
      new Date().getDate() == day;
    console.log(isToday);

    //check to see if location is a phone number
    if (location.length < 17)
      return <><IonButton
      disabled={
        !isToday || appointment["status"] == "cancelled" || cancelled
      }
      href={location}
      className="ion-margin-bottom join-session-button"
      expand="block"
      target="_blank"
    >
      Call (Phone) {location}
    </IonButton>
    <p>Member put preference as Phone Call for this one</p></>;

    //if location contains the word calendly
    if (location.includes("calendly"))
      return (
        <IonButton
          disabled={
            !isToday || appointment["status"] == "cancelled" || cancelled
          }
          href={location}
          className="ion-margin-bottom join-session-button"
          expand="block"
          target="_blank"
        >
          Join (Teams)
        </IonButton>
      );

    return (
      <>
        <IonButton
       
          routerLink={"/meeting/" + window.location.pathname.split("/")[2]}
          className="ion-margin-bottom join-session-button"
          expand="block"
        >
          Join
        </IonButton>
      </>
    );
  };

  const cancelSession = async () => {
    const id = window.location.pathname.split("/")[2];
    const docRef = doc(getFirestore(), "sessions", id);
    await updateDoc(docRef, {
      status: "cancelled",
    });
    fetchSessions();
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Session</IonTitle>
          </IonToolbar>
        </IonHeader>
        {appointment["coachUid"] !== undefined ? (
          <IonCard className="session-card ion-padding">
            {appointment["location"]}
            <RenderMemberInfo
              memberUid={appointment["memberUid"] || "nothing"}
            />

            <RenderJoinCallButton
              cometChatUid={appointment["memberCometChatUid"]}
              startTime={appointment["startTime"]}
              location={appointment["location"]}
            />

            <div className="ion-padding-bottom">
              <a
                className="session-cancel"
                onClick={() => setShowCancelAlert(true)}
              >
                Cancel
              </a>
            </div>
            <IonAlert
              isOpen={showCancelAlert}
              onDidDismiss={() => setShowCancelAlert(false)}
              message="Are you sure you want to cancel this appointment? This action cannot be undone."
              buttons={[
                {
                  text: "No",
                  role: "cancel",
                  handler: () => {},
                },
                {
                  text: "Yes",
                  role: "confirm",
                  handler: () => {
                    cancelSession();
                    setCancelled(true);
                  },
                },
              ]}
            />
          </IonCard>
        ) : (
          <IonCard className="session-card">
            <IonCardHeader>
              <IonCardTitle>Appointment Pending</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p> Your appointment is pending. Please come back later.</p>
              <IonButton routerLink="/page/appointment">Back</IonButton>
            </IonCardContent>
          </IonCard>
        )}
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Notes</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonInput
              value={appointment["coachNotes"]}
              placeholder="Enter notes"
              onIonChange={(e) => (appointment["coachNotes"] = e.detail.value)}
            ></IonInput>
            <IonButton onClick={updateCoachNotes}>Update</IonButton>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Coach only Notes</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonInput
              value={appointment["coachOnlyNotes"]}
              placeholder="Enter Coach Only notes"
              onIonChange={(e) =>
                (appointment["coachOnlyNotes"] = e.detail.value)
              }
            ></IonInput>
            <IonButton onClick={updateCoachOnlyNotes}>Update</IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Session;