import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { CometChatUI } from "../../components/chat/cometchat-pro-react-ui-kit-master/CometChatWorkspace/src";

const Chat: React.FC = () => {
  const [openChatWithUser, setOpenChatWithUser] = useState<string>("");

  const getLastPartOfUrl = () => {
    const url = window.location.href;
    const urlParts = url.split("/");
    const lastPart = urlParts[urlParts.length - 1];
    console.log("last part of url", lastPart);
    if (lastPart !== "chat" && lastPart !== "messages") {
      setOpenChatWithUser(lastPart);
    }
    return lastPart;
  };

  useEffect(() => {
    //set timeout for login
    getLastPartOfUrl();
  }, []);

 



  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Chat</IonTitle>
          </IonToolbar>
        </IonHeader>
        {openChatWithUser !== "" ? ( 
          <CometChatUI chatWithUser={openChatWithUser} />
        ) : ( <CometChatUI />)}
       
      </IonContent>
    </IonPage>
  );
};

export default Chat;
