import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToast,
} from "@ionic/react";
import {
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
  writeBatch,
  collection,
  getDoc,
} from "firebase/firestore";

import React, { useContext } from "react";
import {
  validateEmail,
  validateFirstName,
  validatePhone,
  validateLastName,
  reformatDOB,
} from "../../lib/helpers";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../../lib/context";

import "./Add.css";

export default function AddIndividualMember() {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [addr1, setAddr1] = React.useState("");
  const [addr2, setAddr2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [externalID, setExternalID] = React.useState("");
  const [walmartHealthId, setWalmartHealthId] = React.useState("");
  const [access, setAccess] = React.useState("");
  const [subProducts, setSubProducts] = React.useState([]);
  const [dependent, setDependent] = React.useState(false);

  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("");

  const [member, setMember] = React.useState<string>();
  const [groupId, setGroupId] = React.useState<string>();
  const [groups, setGroups] = React.useState([]);
  const { user, profile } = useContext(UserContext);

  const states = [
    { name: "None", value: "" },

    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const addMember = async (e: { preventDefault: () => void; target }) => {
    e.preventDefault();

    if (
      helpEmail() &&
      helpFirstName() &&
      helpLastName() &&
      helpPhone() &&
      helpAccess()
    ) {
      var dobTimeStamp;

      if (dob !== "") {
        dobTimeStamp = new Date(dob) || "";
      } else {
        dobTimeStamp = "";
      }

      const batch = writeBatch(getFirestore());

      const userDoc = doc(
        getFirestore(),
        "triada_members",
        email.toLowerCase()
      );

      const groupIDandName = groupId.split("%");
      // const dobArray = dob.split("-");
      // const dobYear = dobArray[0];
      // const dobMonth = dobArray[1];
      // const dobDay = dobArray[2];
      const formattedDob = reformatDOB(dob);

      const member = {
        firstName: firstName || "",
        lastName: lastName || "",
        dob: formattedDob || "",
        dobTimeStamp: dobTimeStamp || "",
        addr1: addr1 || "",
        addr2: addr2 || "",
        externalID: externalID || "",
        city: city || "",
        state: state || "",
        zip: zip || "",
        email: email.toLowerCase(),
        phone: phone || "",
        groupID: groupIDandName[0],
        groupName: groupIDandName[1],
        coach: user.uid,
        dependent: dependent || false,
        access: access || "",
        hasTelemedicine:
          subProducts.includes("telemedicine") || access === "telemedicineOnly"
            ? true
            : false,
        hasFacescan:
          subProducts.includes("facescan") || access === "facescanOnly"
            ? true
            : false,
        hasCoaching: subProducts.includes("coaching"),
        hasQuestis: subProducts.includes("questis"),
        walmartHealthPlanCode: groupIDandName[2], // this is the 3rd element in the value selected from group
      };

      if (walmartHealthId !== "") {
        member["walmartHealthId"] = walmartHealthId;
      }

      console.log("member", member);

      //check to see if account already exists in triada_members where email = email
      const q = query(
        collection(getFirestore(), "triada_members"),
        where("email", "==", email.toLowerCase())
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.size === 0) {
        //if account does not exist, create it

        batch.set(userDoc, member);
        console.log("member", member);

        batch.commit().then(() => {
          setToastColor("success");
          setToastMessage("User successfully added " + email);
          setShowToast(true);
        });
      } else {
        setToastColor("danger");
        setToastMessage("Account already exists");
        setShowToast(true);
        setAccess("");
        setSubProducts([]);
        setAddr1("");
        setAddr2("");
        setCity("");
        setDob("");
        setEmail("");
        setDependent(false);
        setExternalID("");
        setFirstName("");
        setLastName("");
        setPhone("");
        setZip("");
        setState("");
        setWalmartHealthId("");
      }

      e.target.reset();
    }

    // if (validateEmail(member) && member !== "") {

    // }
  };

  const helpEmail = () => {
    const emailInput = document.getElementById("email-input");
    console.log("email", email);
    console.log(validateEmail(email));

    if (email === "") {
      emailInput.classList.add("wrong");
      return false;
    } else if (!validateEmail(email)) {
      emailInput.classList.add("wrong");
      return false;
    } else {
      emailInput.classList.remove("wrong");
      return true;
    }
  };

  const helpFirstName = () => {
    const firstNameInput = document.getElementById("firstName-input");

    if (firstName === "") {
      firstNameInput.classList.remove("wrong");
      return true;
    } else if (!validateFirstName(firstName)) {
      firstNameInput.classList.add("wrong");
      return false;
    } else {
      firstNameInput.classList.remove("wrong");
      return true;
    }
  };

  const helpLastName = () => {
    const lastNameInput = document.getElementById("lastName-input");

    if (lastName === "") {
      lastNameInput.classList.remove("wrong");
      return true;
    } else if (!validateLastName(lastName)) {
      lastNameInput.classList.add("wrong");
      return false;
    } else {
      lastNameInput.classList.remove("wrong");
      return true;
    }
  };

  const helpPhone = () => {
    const phoneInput = document.getElementById("phone-input");
    console.log("phone", phone);

    if (phone === "") {
      phoneInput.classList.remove("wrong");
      return true;
    } else if (!validatePhone(phone)) {
      phoneInput.classList.add("wrong");
      return false;
    } else {
      phoneInput.classList.remove("wrong");
      return true;
    }
  };

  const helpAccess = () => {
    const accessE = document.getElementById("access-input");
    console.log("access", access);

    if (access === "") {
      accessE.classList.add("wrong");
      return false;
    } else {
      accessE.classList.remove("wrong");

      return true;
    }
  };

  const accessOnChange = () => {
    const accessE = document.getElementById("access-input");
    console.log("accesswww", access);

    if (access === "") {
      accessE.classList.add("wrong");
      setSubProducts([]);
    } else {
      accessE.classList.remove("wrong");
      if (access === "fullAccess")
        setSubProducts(["facescan", "coaching", "telemedicine", "questis"]);
      else setSubProducts([]);
    }
  };

  const validateExternalId = (externalId: string) => {
    // check to see if there are any spaces in the externalId
    if (externalId.includes(" ")) {
      return false;
    }
    return true;
  };



  const validateWalmartHealthId = (walmartHealthIdString: string) => {
    // check to see if there are any spaces in the externalId
    if (walmartHealthIdString.includes(" ")) {
      return false;
    }
    return true;
  };

 

  //fetch specialities from firebase
  const fetchGroups = async () => {
    const q = query(
      collection(getFirestore(), "groups"),
      where("active", "==", true),
      orderBy("group", "asc")
    );

    var groupsData = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      groupsData.push(doc.data());
    });

    groupsData.sort((a, b) => {
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    });

    setGroups(groupsData);
  };

  const listGroups = groups.map((group) => (
    <IonSelectOption
      key={group.groupID}
      value={
        group.groupID + "%" + group.group + "%" + group.walmartHealthPlanCode
      }
    >
      {group.group}
    </IonSelectOption>
  ));

  const renderStates = states.map((state) => (
    <IonSelectOption key={state.abbreviation} value={state.name}>
      {state.name}
    </IonSelectOption>
  ));

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Add Individual Member </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <form onSubmit={addMember}>
          <div className="solo-box-cont">
            <IonItem lines="none">
              <IonLabel position="stacked">Group</IonLabel>
              <IonSelect
                onFocus={(e) => fetchGroups()}
                cancelText="Cancel"
                okText="Okay"
                onIonChange={(e) => {
                  setGroupId(e.detail.value);
                }}
              >
                {listGroups}
              </IonSelect>
            </IonItem>
          </div>

          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={4000}
            position="top"
            color={toastColor}
          />

          {/* <IonItem>
        <IonLabel position="stacked">Member Email</IonLabel>
        <IonInput
          required
          placeholder=""
          onIonChange={(e) => setMember(e.detail.value)}
          onIonBlur={(e) => helpEmail()}
        ></IonInput>
        <div id="add-member-help"></div>
      </IonItem> */}
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <div id="email-input" className="solo-box-cont">
                    <p className="input-title">Email*</p>
                    <IonInput
                      required
                      onIonChange={(e) => setEmail(e.detail.value)}
                      onIonBlur={(e) => helpEmail()}
                      autocomplete="email"
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="none">
                  <div id="phone-input" className="solo-box-cont">
                    <p className="input-title">Phone Number (xxx-xxx-xxxx)</p>
                    <IonInput
                      onIonChange={(e) => setPhone(e.detail.value)}
                      onIonBlur={(e) => helpPhone()}
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
              <IonCol>
                <div id="access-input" className="solo-box-cont">
                  <p className="input-title">Access Level*</p>
                  <IonSelect
                    onIonChange={(e) => {
                      setAccess(e.detail.value);
                      helpAccess();
                      accessOnChange();
                    }}
                    onIonDismiss={(e) => {
                      helpAccess();
                      accessOnChange();
                    }}
                  >
                    <IonSelectOption value="telemedicineOnly">
                      Telemedicine Only
                    </IonSelectOption>
                    <IonSelectOption value="facescanOnly">
                      Facescan Only
                    </IonSelectOption>
                    <IonSelectOption value="fullAccess">
                      Full Access
                    </IonSelectOption>
                  </IonSelect>
                </div>
                {access == "fullAccess" && (
                  <div id="externalId-input" className="solo-box-cont">
                    <p className="input-title">
                      Sub Products (Full Access Only)
                    </p>
                    <IonSelect
                      multiple
                      onIonChange={(e) => setSubProducts(e.detail.value)}
                      value={subProducts}
                    >
                      <IonSelectOption value="telemedicine">
                        Telemedicine
                      </IonSelectOption>
                      <IonSelectOption value="facescan">
                        Facescan
                      </IonSelectOption>
                      <IonSelectOption value="coaching">
                        Coaching
                      </IonSelectOption>
                      <IonSelectOption value="questis">Questis</IonSelectOption>
                    </IonSelect>
                  </div>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
              <IonLabel>
                  <p>Dependent</p><IonCheckbox checked={dependent} onIonChange={(e) => setDependent(e.detail.checked)} />
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <div id="firstName-input" className="small-box-cont">
                    <p className="input-title">First Name</p>
                    <IonInput
                      onIonChange={(e) => setFirstName(e.detail.value)}
                      onIonBlur={(e) => helpFirstName()}
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="none">
                  <div id="lastName-input" className="small-box-cont">
                    <p className="input-title">Last Name</p>
                    <IonInput
                      onIonChange={(e) => setLastName(e.detail.value)}
                      onIonBlur={(e) => helpLastName()}
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="none">
                  <div className="small-box-cont">
                    <p className="input-title">DOB*</p>
                    <IonInput
                      type="date"
                      onIonChange={(e) => setDob(e.detail.value)}
                      required
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="none">
                  <div className="small-box-cont">
                    <p className="input-title">Address 1</p>
                    <IonInput
                      onIonChange={(e) => setAddr1(e.detail.value)}
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <div className="small-box-cont">
                    <p className="input-title">Address 2</p>
                    <IonInput
                      onIonChange={(e) => setAddr2(e.detail.value)}
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="none">
                  <div className="small-box-cont">
                    <p className="input-title">State</p>
                    <IonSelect
                      onIonChange={(e) => {
                        setState(e.detail.value);
                      }}
                    >
                      {renderStates}
                    </IonSelect>
                  </div>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="none">
                  <div className="small-box-cont">
                    <p className="input-title">City</p>
                    <IonInput
                      onIonChange={(e) => setCity(e.detail.value)}
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="none">
                  <div className="small-box-cont">
                    <p className="input-title">Zip</p>
                    <IonInput
                      onIonChange={(e) => setZip(e.detail.value)}
                      className="input-boxed"
                    ></IonInput>
                  </div>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonButton id="add-member-button" type="submit">
            Add Member
          </IonButton>
        </form>
      </IonCardContent>
    </IonCard>
  );
}
