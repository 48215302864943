import {
  IonCardHeader,
  IonItem,
  IonAvatar,
  IonLabel,
  IonIcon,
  IonModal,
  useIonAlert,
  IonPopover,
  IonContent,
  IonButton,
  IonButtons,
  IonHeader,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
  IonChip,
} from "@ionic/react";
import "./style.css";
import Avatar from "../Avatar";
import {
  addOutline,
  body,
  closeOutline,
  ellipsisHorizontal,
  eye,
  eyeOff,
  pencilSharp,
  trashBin,
} from "ionicons/icons";
import { useContext, useState } from "react";
import { UserContext } from "../../../../lib/context";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { InterestList } from "../../../../Interfaces/Interest";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Question } from "../../../../Interfaces/Question";
import { usePhotoGallery } from "../../../../lib/usePhotoGallery";
import StatusChip from "./StatusChip";
import ClaimQuestionButton from "./ClaimQuestionButton";
import { ClinicalConcernsList } from "../../../../Interfaces/ClinicalConcerns";

type Props = {
  avatar: string;
  name: string;
  category: string;
  time: string;
  publicAvatarUid: string;
  questionId: string;
  isHidden: boolean;
  refreshHandler;
  question: any;
};

const HeaderItem: React.FC<Props> = ({
  avatar,
  name,
  category,
  time,
  publicAvatarUid,
  refreshHandler,
  question,
  questionId,
  isHidden,
}) => {
  const { profile } = useContext(UserContext);

  const [hidden, setHidden] = useState(isHidden);

  const [editOpen, setEditOpen] = useState(false);

  const [title, setTitle] = useState(question.title);
  const [body, setBody] = useState(question.body);
  const [catagory, setCatagory] = useState(question.category);

  const { takePhoto, clearPhoto } = usePhotoGallery();
  const [photo, setPhoto] = useState({}) as any;

  const [postDeleted, setPostDeleted] = useState(false);

  const [presentAlert] = useIonAlert();

  const deleteQuestion = () => {
    presentAlert({
      header: "Delete Question",
      message: "Are you sure you want to delete this question?",
      buttons: [
        "Cancel",
        {
          text: "Delete",
          handler: () => {
            deleteFromDatabase();
          },
        },
      ],
    });
  };

  const deleteFromDatabase = async () => {
    await deleteDoc(doc(getFirestore(), "koroQuestions", questionId));
    setPostDeleted(true);
    refreshHandler();
  };

  if (postDeleted) {
    return null;
  }

  const updateHidden = (hiddenValue) => {
    console.log("updating hidden", hiddenValue);
    // setHidden(!hidden);

    const documentRef = doc(getFirestore(), "koroQuestions", questionId);

    updateDoc(documentRef, {
      hidden: !hiddenValue,
    }).then(() => {
      console.log("Document successfully updated!");
      setHidden(!hiddenValue);
      refreshHandler();
    });
  };

  var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  const addImage = async () => {
    // setPhotoLoading(true);
    takePhoto().then(async (photo) => {
      getFileBlob(photo.webPath, (blob) => {
        setPhoto({ photo, blob });
      });
    });
  };

  const createQuestion = async () => {
    const question: Question = {
      title,
      body,
      category: catagory,
      expert: true,
      photo: null,
      timeStamp: new Date(),
      upvotes: [],
      downvotes: [],
      comments: [],
      hidden: false,
      shareLinks: "",
      poster: profile.uid,
      posterDisplayName: profile.firstName + " " + profile.lastName,
      posterAvatar: profile.photoURL,
      posterGender: "koroPublicAvatar.displayGender",
    };

    if (photo.photo) {
      const storage = getStorage();
      const profilePhotoRef = ref(
        storage,
        "questionCoverImage/" + photo.photo.webPath
      );
      uploadBytes(profilePhotoRef, photo.blob).then(() => {
        getDownloadURL(profilePhotoRef).then(async (url) => {
          question.photo = url;

          // const docRef = collection(getFirestore(), "koroQuestions");//

          const updateDocRef = doc(getFirestore(), "koroQuestions", questionId);

          updateDoc(updateDocRef, { ...question })
            .then(() => {
              console.log("Document successfully written!");
              setEditOpen(false);
              refreshHandler();
              // setTitle("");
              // setBody("");
              // setCatagory("");
              setPhoto({});
              clearPhoto();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        });
      });
    } else {
      const updateDocRef = doc(getFirestore(), "koroQuestions", questionId);

      updateDoc(updateDocRef, { ...question })
        .then(() => {
          console.log("Document successfully written!");
          setEditOpen(false);
          refreshHandler();
          // setTitle("");
          // setBody("");
          // setCatagory("");
          setPhoto({});
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  };

  const RenderClinicalConcernTitle = ({ clinicalConcern }) => {
    // find the clinical concern object with id clinicalConcern in ClinicalConcernList
    const clinicalConcernObject = ClinicalConcernsList.find(
      (item) => item.id === clinicalConcern
    );

    return (
      <>
        <span className="koroQuestionCategory">{clinicalConcernObject.title}</span>
      </>
    );
  };

  return (
    <>
      <IonCardHeader>
        <IonItem className="ion-no-padding ion-no-margin koroQuestionHeaderItem">
          {publicAvatarUid == profile.uid && (
            <IonIcon
              icon={ellipsisHorizontal}
              size="medium"
              className="moreRight"
              slot="end"
              id={questionId}
            />
          )}
          <IonPopover trigger={questionId} triggerAction="click">
            <IonContent>
              <IonItem lines="none" onClick={deleteQuestion}>
                <IonIcon icon={trashBin} size="medium" slot="start" />
                <IonLabel>Delete</IonLabel>
              </IonItem>
              {hidden && (
                <IonItem lines="none" onClick={() => updateHidden(hidden)}>
                  <IonIcon icon={eye} size="medium" slot="start" />
                  <IonLabel>Show</IonLabel>
                </IonItem>
              )}
              {!hidden && (
                <IonItem lines="none" onClick={() => updateHidden(hidden)}>
                  <IonIcon icon={eyeOff} size="medium" slot="start" />
                  <IonLabel>Hide</IonLabel>
                </IonItem>
              )}

              <IonItem lines="none" onClick={() => setEditOpen(true)}>
                <IonIcon icon={pencilSharp} size="medium" slot="start" />
                <IonLabel>Edit</IonLabel>
              </IonItem>
            </IonContent>
          </IonPopover>
          <Avatar
            src={avatar}
            publicAvatarUid={publicAvatarUid}
            expert={question.expert}
          />
          <IonLabel>
            <h2 className="bold">{name}</h2>
            <p className="ion-text-wrap">
            <RenderClinicalConcernTitle
              clinicalConcern={question.clinicalConcern}
            /> · {time}{" "}
              ago
            </p>
          </IonLabel>
          <IonLabel>
           
          </IonLabel>
          <StatusChip status={question.status} />
          <ClaimQuestionButton q={question} />
        </IonItem>
      </IonCardHeader>

      <IonModal isOpen={editOpen}>
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setEditOpen(false)} slot="start">
                <IonIcon icon={closeOutline} size="large" />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                disabled={title === "" || catagory == ""}
                onClick={createQuestion}
              >
                Update
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonItem lines="none">
          <IonTextarea
            placeholder="Title"
            autofocus
            autoGrow={true}
            value={title}
            onIonInput={(e: any) => setTitle(e.target.value)}
            className="koroAskQuestionTitle ion-no-padding ion-no-margin"
          >
            {" "}
          </IonTextarea>
        </IonItem>
        <IonItem lines="none">
          <IonTextarea
            placeholder="Body text (optional)"
            className="koroAskQuestionBody ion-no-padding ion-no-margin"
            value={body}
            autoGrow={true}
            onIonInput={(e: any) => setBody(e.target.value)}
          ></IonTextarea>
        </IonItem>
        {/* <div className="inputItemForm ion-margin ">
          <IonSelect
            onIonChange={(e: any) => setCatagory(e.target.value)}
            placeholder="Question Catagory"
            value={catagory}
            className="ion-no-padding ionSelect"
          >
            {InterestList.map((interest, index) => {
              return (
                <IonSelectOption value={interest.title} key={interest.title}>
                  {interest.title}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </div> */}

        {photo.photo || question.photo ? (
          <>
            <div className="koroQuestionHeaderImgWrapper">
              <img
                className="koroQuestionHeaderImg"
                src={photo?.photo?.webPath || question.photo}
              />
            </div>
            <p
              onClick={() => {
                setPhoto({});
                question.photo = null;
              }}
              className="ion-margin koroAddImageLink ion-text-center"
            >
              Remove
            </p>
          </>
        ) : (
          <p
            className="ion-margin ion-padding koroAddImageLink ion-text-center"
            onClick={addImage}
          >
            Add image
          </p>
        )}
      </IonModal>
    </>
  );
};

export default HeaderItem;
