import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import "./Bodee.css";
//import spinner
import React, { useEffect, useState } from "react";
import { refresh } from "ionicons/icons";
import MessageBox from "../../components/Bodee/MessageBox";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
} from "@firebase/firestore";
import QuestionCard from "../../components/Bodee/QuestionCard";
import CoachFocusSection from "../../components/Bodee/CoachFocusSection";
import ClinicalConcernSlider from "../../components/Bodee/ClinicalConcernSlider";
import GoalSlider from "../../components/Bodee/GoalSlider";
import InterestSlider from "../../components/Bodee/InterestSlider";

const Bodee: React.FC = () => {
  const [questionsData, setQuestionsData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [viewAllQuestions, setViewAllQuestions] = useState(false);
  const [viewAllQuestionsData, setViewAllQuestionsData] = useState([]);

  const [homeSegment, setHomeSegment] = useState("goals");
  const [focus, setFocus] = useState("all");

  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const [waitingForMember, setWaitingForMember] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [waitingForAnswer, setWaitingForAnswer] = useState(true);

  const getQuestions = async () => {
    var questions = [];

    var q = query(
      collection(getFirestore(), "koroQuestions"),
      where("hidden", "==", false),
      where("status", "!=", "answered")
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // questions.push({ id: doc.id, ...doc.data() });
      if (doc.data().dateCreated) {
        questions.push({ id: doc.id, ...doc.data() });
      }
    });

    // sort questions by timestamp.seconds in ascending order
    questions.sort((a, b) => {
      return b.dateCreated.seconds - a.dateCreated.seconds;
    });

    // put questions with status waitingForAnswer at the top
    questions.sort((a, b) => {
      return a.status === "waitingForAnswer"
        ? -1
        : b.status === "waitingForAnswer"
        ? 1
        : 0;
    });

    setQuestionsData(questions);
    setFilteredQuestions(questions);
  };

  useEffect(() => {
    console.log("useEffecting!!!");
    getQuestions();
  }, []);

  const getAllQuestions = async () => {
    var questions = [];

    var q = query(
      collection(getFirestore(), "koroQuestions"),
      where("hidden", "==", false)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data().dateCreated) {
        questions.push({ id: doc.id, ...doc.data() });
      }
    });

    // sort questions by timestamp.seconds in ascending order
    questions.sort((a, b) => {
      return b.dateCreated.seconds - a.dateCreated.seconds;
    });

    // put questions with status waitingForAnswer at the top
    questions.sort((a, b) => {
      return a.status === "waitingForAnswer"
        ? -1
        : b.status === "waitingForAnswer"
        ? 1
        : 0;
    });

    setViewAllQuestionsData(questions);
  };

  useEffect(() => {
    if (viewAllQuestions) {
      getAllQuestions();
    }
  }, [viewAllQuestions]);

  const filterDownQuestions = async () => {
    var questions = [];

    // filter down questions based on claimed and status (waitingForAnswer or waitingForMember)
    if (claimed) {
      questions = questionsData.filter((question) => {
        return question.claimed === true;
      });
    } else {
      questions = questionsData.filter((question) => {
        return question.claimed !== true;
      });
    }

    if (waitingForAnswer) {
      questions = questions.filter((question) => {
        return question.status === "waitingForAnswer";
      });
    } else {
      questions = questions.filter((question) => {
        return question.status === "waitingForMember";
      });
    }

    setFilteredQuestions(questions);
  };

  useEffect(() => {
    filterDownQuestions();
  }, [claimed, waitingForAnswer, questionsData]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonMenuButton />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Bodee</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="ion-margin-top">
          <div className="centerChips">
            <IonChip
              className="sortChipSecondary"
              outline={homeSegment === "goals" ? true : false}
              onClick={() => setHomeSegment("goals")}
            >
              Goals
            </IonChip>
            <IonChip
              className="sortChipSecondary"
              outline={homeSegment === "interest" ? true : false}
              onClick={() => setHomeSegment("interest")}
            >
              Interests
            </IonChip>
            <IonChip
              className="sortChipSecondary"
              outline={homeSegment === "clinical-concerns" ? true : false}
              onClick={() => setHomeSegment("clinical-concerns")}
            >
              Clinical Concerns
            </IonChip>
          </div>

          {homeSegment === "clinical-concerns" && (
            <>
              {" "}
              <h3 className="ion-margin">Clinical Concerns</h3>
              <ClinicalConcernSlider refresh={refresh} focus={focus} />
            </>
          )}

          {homeSegment === "goals" && (
            <>
              <h3 className="ion-margin">Goals</h3>
              <GoalSlider refresh={refresh} focus={focus} />
            </>
          )}

          {homeSegment === "interest" && (
            <>
              <h3 className="ion-margin">Interest</h3>
              <InterestSlider refresh={refresh} focus={focus} />
            </>
          )}

          {/* <MessageBox /> */}
          {/* {questionsData.length} */}
          <CoachFocusSection questions={questionsData} />
          <IonItem>
            <IonLabel>All Questions</IonLabel>
            <IonToggle
              checked={viewAllQuestions}
              onIonChange={(e) => setViewAllQuestions(e.detail.checked)}
            />
          </IonItem>

          {viewAllQuestions ? (
            <>
                            <h3 className="ion-margin">All Questions</h3>

              {viewAllQuestions && (
                <>
                  {viewAllQuestionsData.map((question, index) => {
                    return (
                      <QuestionCard
                        question={question}
                        refresh={refresh}
                        key={question.id}
                        refreshHandler={undefined}
                      />
                    );
                  })}
                </>
              )}
            </>
          ) : (
            <>
              <IonChip
                className="sortChipSecondary"
                onClick={() => setClaimed(!claimed)}
                outline={claimed ? true : false}
              >
                {claimed ? "Claimed" : "Unclaimed"}
              </IonChip>
              <IonChip
                className="sortChipSecondary"
                onClick={() => setWaitingForAnswer(!waitingForAnswer)}
                outline={waitingForAnswer ? true : false}
              >
                {waitingForAnswer ? "Waiting For Answer" : "Waiting For Member"}
              </IonChip>
              <h3 className="ion-margin">Filtered Questions</h3>
              {filteredQuestions.map((question, index) => {
                return (
                  <QuestionCard
                    question={question}
                    refresh={refresh}
                    key={question.id}
                    refreshHandler={undefined}
                  />
                );
              })}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Bodee;
