import React from "react";
import "./style.css";
import { query, collection, getFirestore, where, getDocs } from "@firebase/firestore";

export function CometChatNameLink({ name, uid }) {


  const handleClick = () => {
  const q = query(
    collection(getFirestore(), "users"),
    where("cometChatUid", "==", uid),
    where("role", "==", "member")
  );


  getDocs(q).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      window.location.href = "/member-dash/" + doc.id;
    });
  });
  
  };
  

  return (
    <>
      <p className="DashLinkName" onClick={() => handleClick()}><b>{name}</b></p>
    </>
  );
}
