import {
  IonMenu,
  IonContent,
  IonCard,
  IonList,
  IonListHeader,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonRow,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import {
  home,
  people,
  documents,
  addCircle,
  chatbox,
  calendar,
  eyeSharp,
  personCircleSharp,
  lockClosedSharp,
  toggleSharp,
  appsSharp,
  gitBranch,
  bus,
  business,
  barChartSharp,
  documentOutline,
  documentSharp,
  walletOutline,
  wallet,
} from "ionicons/icons";

import "./Menu.css";
import { useContext } from "react";
import { UserContext } from "../lib/context";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { usePhotoGallery } from "../lib/usePhotoGallery";
import { CometChat } from "@cometchat-pro/chat";
import { auth } from "../lib/firebase";
import axios from "axios";
import React from "react";
import { AppPage } from "./AppPage";
import { nameColumnStyle } from "./chat/cometchat-pro-react-ui-kit-master/CometChatWorkspace/src/components/Groups/CometChatViewGroupMemberListItem/style";

const appPages: AppPage[] = listOfAddPages();

const Menu: React.FC = () => {
  const location = useLocation();
  const { user, profile } = useContext(UserContext);
  const { takePhoto, clearPhoto } = usePhotoGallery();
  const [notificationNumber, setNotificationNumber] = React.useState();
  const [selectedTab, setSelectedTab] = React.useState("");

  const getUnreadMessages = async () => {
    //console.log("user.accessToken", user.accessToken);
    console.log("Getting unread messages");
    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/Notification-GetCometChatNotificationsNumber`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: {},
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data == "0") {
          setNotificationNumber(null);
        } else {
          setNotificationNumber(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changingTab = (tab) => {
    console.log("changing from", selectedTab, "to", tab);

    if (tab === "chat") {
      getUnreadMessages();
    }

    if (selectedTab === "chat") {
      getUnreadMessages();
    }

    setSelectedTab(tab);
  };

  React.useEffect(() => {
    // get unread messages from api using axios
    getUnreadMessages();
  }, []);

  const logOut = async () => {
    CometChat.logout();
    auth.signOut();
    window.location.href = "/login";
  };

  var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  const changeProfilePhoto = async () => {
    takePhoto().then(async (photo) => {
      getFileBlob(photo.webPath, (blob) => {
        const storage = getStorage();
        const profilePhotoRef = ref(storage, "profile_pictures/" + user.uid);
        uploadBytes(profilePhotoRef, blob).then(() => {
          getDownloadURL(profilePhotoRef).then(async (url) => {
            const ref = doc(getFirestore(), "users", user.uid);
            await updateDoc(ref, {
              photoURL: url,
            });

            clearPhoto();
          });
        });
      });
    });
  };

  return (
    <IonMenu contentId="main" type="overlay" id="mainMenu">
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonRow>
              <IonCol col-4></IonCol>
              <IonCol col-4>
                <div className="wrapper" onClick={changeProfilePhoto}>
                  <img src={profile.photoURL} alt="" />
                  <div className="edit-centered ">Edit</div>
                </div>
              </IonCol>

              <IonCol col-4></IonCol>
            </IonRow>
            <IonLabel className="name">
              {profile.firstName + " " + profile.lastName}
            </IonLabel>
          </IonCardHeader>

          <IonCardContent>
            <IonLabel>{profile.email}</IonLabel>
          </IonCardContent>
        </IonCard>
        <IonList id="inbox-list">
          <IonListHeader>Coach Dashboard</IonListHeader>
          {appPages.map((appPage, index) => {
            if (appPage.permissionNeeded !== undefined) {
              // check if appPermission is in user.permissions array

              if (profile.permissionRoles === undefined) {
                return null;
              }

              // if (!profile.permissionRoles.includes(appPage.permissionNeeded)) {
              //   return null;
              // }

              // see if profile.permissionsRoles array contains and values from appPage.permissionNeeded array
              var found = profile.permissionRoles.some(function (v) {
                return appPage.permissionNeeded.includes(v);
              });

              if (!found) {
                return null;
              }
            }

            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                  onClick={() => changingTab(appPage.title.toLowerCase())}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                  {appPage.title === "Chat" ? (
                    <IonBadge color="danger">{notificationNumber}</IonBadge>
                  ) : null}
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        <IonButton expand="block" onClick={logOut}>
          Log Out
        </IonButton>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

function listOfAddPages(): AppPage[] {
  return [
    {
      title: "Home",
      url: "/page/home",
      iosIcon: home,
      mdIcon: home,
      permissionNeeded: ["coach"],
    },
    {
      title: "Members",
      url: "/page/members",
      iosIcon: people,
      mdIcon: people,
      permissionNeeded: ["coach"],
    },
    {
      title: "Community",
      url: "/page/bodee",
      iosIcon: business,
      mdIcon: business,
    },
    {
      title: "Affiliates",
      url: "/page/affiliates",
      iosIcon: wallet,
      mdIcon: wallet,
      permissionNeeded: ["admin"],
    },
    {
      title: "Reporting",
      url: "/page/Reporting",
      iosIcon: barChartSharp,
      mdIcon: barChartSharp,
      permissionNeeded: ["coach"],
    },
    {
      title: "Add",
      url: "/page/add",
      iosIcon: addCircle,
      mdIcon: addCircle,
      permissionNeeded: ["coach"],
    },
    {
      title: "Eligibility",
      url: "/page/eligibility",
      iosIcon: documentSharp,
      mdIcon: documentSharp,
      permissionNeeded: ["coach"],
    },
    {
      title: "Chat",
      url: "/page/chat",
      iosIcon: chatbox,
      mdIcon: chatbox,
      permissionNeeded: ["coach"],
    },
    {
      title: "Communication",
      url: "/page/communication",
      iosIcon: gitBranch,
      mdIcon: gitBranch,
      permissionNeeded: ["coach"],
    },
    {
      title: "Appointment",
      url: "/page/appointment",
      iosIcon: calendar,
      mdIcon: calendar,
      permissionNeeded: ["coach"],
    },
    {
      title: "Content",
      url: "/page/content",
      iosIcon: eyeSharp,
      mdIcon: eyeSharp,
      permissionNeeded: ["coach"],
    },
    {
      title: "Coaches",
      url: "/page/coaches",
      iosIcon: appsSharp,
      mdIcon: appsSharp,
      permissionNeeded: ["coach"],
    },
    {
      title: "Profile",
      url: "/page/MyProfile",
      iosIcon: personCircleSharp,
      mdIcon: personCircleSharp,
      permissionNeeded: ["coach"],
    },
    {
      title: "My Account",
      url: "/page/MyAccount",
      iosIcon: lockClosedSharp,
      mdIcon: lockClosedSharp,
      permissionNeeded: ["coach"],
    },
    {
      title: "Preferences",
      url: "/page/preferences",
      iosIcon: toggleSharp,
      mdIcon: toggleSharp,
      permissionNeeded: ["coach"],
    },
  ];
}
