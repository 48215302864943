import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import "./index.css";
import { useContext, useState } from "react";
import { UserContext } from "../../../lib/context";
import { addOutline, closeOutline } from "ionicons/icons";
import { usePhotoGallery } from "../../../lib/usePhotoGallery";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { InterestList } from "../../../Interfaces/Interest";
import {
  addDoc,
  collection,
  getFirestore,
} from "firebase/firestore";

import {Question} from "../../../Interfaces/Question";


const MessageBox: React.FC = () => {
  const { profile } = useContext(UserContext);
  const [askAQuestion, setAskAQuestion] = useState(false);
  const { takePhoto, clearPhoto } = usePhotoGallery();
  const [photo, setPhoto] = useState({}) as any;

  const [ presentAlert ] = useIonAlert();

  const [disclaimer, setDisclaimer] = useState(false);

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [catagory, setCatagory] = useState("");

  var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  const addImage = async () => {
    // setPhotoLoading(true);
    takePhoto().then(async (photo) => {
      getFileBlob(photo.webPath, (blob) => {
        setPhoto({ photo, blob });
      });
    });
  };

  const presentDisclaimer = () => {
    presentAlert({
      header: 'Disclaimer',
      message: 'I acknowledge that any advice provided is not diagnostic or designed to treat any health issues',
      buttons: [
        {
          text: 'Do Not Agree',
          role: 'cancel',
        },
        {
          text: 'Agree',
          role: 'confirm',
          handler: () => {
            createQuestion();
          },
        },
      ]}
    );
  };



  const createQuestion = async () => {

    const question: Question = {
      title,
      body,
      category: catagory,
      photo: null,
      timeStamp: new Date(),
      upvotes: [],
      downvotes: [],
      comments: [],
      hidden: false,
      expert: true,
      shareLinks: "",
      poster: profile.uid,
      posterDisplayName: profile.firstName + " " + profile.lastName,
      posterAvatar: profile.photoURL,
      posterGender: "koroPublicAvatar.displayGender",
    };

    if (photo.photo) {
      const storage = getStorage();
      const profilePhotoRef = ref(
        storage,
        "questionCoverImage/" + photo.photo.webPath
      );
      uploadBytes(profilePhotoRef, photo.blob).then(() => {
        getDownloadURL(profilePhotoRef).then(async (url) => {
          question.photo = url;

          const docRef = collection(getFirestore(), "koroQuestions");

          addDoc(docRef, question)
            .then(() => {
              console.log("Document successfully written!");
              setAskAQuestion(false);
              setTitle("");
              setBody("");
              setCatagory("");
              setPhoto({});
              clearPhoto();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        });
      });
    } else {
      const docRef = collection(getFirestore(), "koroQuestions");

      addDoc(docRef, question)
        .then(() => {
          console.log("Document successfully written!");
          setAskAQuestion(false);
          setTitle("");
          setBody("");
          setCatagory("");
          setPhoto({});
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  };

  if (profile.role === "coach")
  return <></>
  

  return (
    <>
      <IonItem
        className="koroMessageBoxItem ion-margin"
        lines="none"
        onClick={() => setAskAQuestion(true)}
      >
        <IonAvatar slot="start" className="ion-margin-top ion-margin-bottom">
          <img src={profile?.photoURL} />
        </IonAvatar>
        <p className="ion-text-wrap whatToShare">
          What do you want to share...
        </p>
      </IonItem>
      <IonModal isOpen={askAQuestion}>
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setAskAQuestion(false)} slot="start">
                <IonIcon icon={closeOutline} size="large" />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                disabled={title === "" || catagory == ""}
                onClick={presentDisclaimer}
              >
                <IonIcon icon={addOutline} /> Post
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonItem lines="none">
          <IonTextarea
            placeholder="Title"
            autofocus
            autoGrow={true}
            value={title}
            onIonInput={(e: any) => setTitle(e.target.value)}
            className="koroAskQuestionTitle ion-no-padding ion-no-margin"
          >
            {" "}
          </IonTextarea>
        </IonItem>
        <IonItem lines="none">
          <IonTextarea
            placeholder="Body text (optional)"
            className="koroAskQuestionBody ion-no-padding ion-no-margin"
            value={body}
            autoGrow={true}
            onIonInput={(e: any) => setBody(e.target.value)}
          ></IonTextarea>
        </IonItem>
        <div className="inputItemForm ion-margin ">
          <IonSelect
            onIonChange={(e: any) => setCatagory(e.target.value)}
            placeholder="Question Catagory"
            value={catagory}
            className="ion-no-padding ionSelect"
          >
            {InterestList.map((interest, index) => {
              return (
                <IonSelectOption value={interest.title} key={interest.title}>
                  {interest.title}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </div>

        {photo.photo ? (
          <>
            <div className="koroQuestionHeaderImgWrapper">
              <img
                className="koroQuestionHeaderImg"
                src={photo.photo.webPath}
              />
            </div>
            <p
              onClick={() => setPhoto({})}
              className="ion-margin koroAddImageLink ion-text-center"
            >
              Remove
            </p>
          </>
        ) : (
          <p
            className="ion-margin ion-padding koroAddImageLink ion-text-center"
            onClick={addImage}
          >
            Add image
          </p>
        )}
      </IonModal>
    </>
  );
};

export default MessageBox;
